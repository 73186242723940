import { phpApi, pyApi } from '@/api/request'
import * as model from '../models'
import { ApiList } from '@/modules/common/models/index'

// 购买前：单内容详情
// wiki：http://yapi.hogecloud.com/project/11/interface/api/1628
export function getFreeContentDetail(id: string): Promise<model.ApiInfo> {
  return phpApi({
    method: 'GET',
    url: `/h5/content/free/detail/${id}`
  })
}

// 购买后：单内容详情
// wiki: http://yapi.hogecloud.com/project/11/interface/api/1067
export function getContentDetail(id: string) : Promise<model.ApiInfo> {
  return phpApi({
    method: 'GET',
    url: `/h5/content/detail/${id}`
  })
}

// 单内容列表
// wiki: http://yapi.hogecloud.com/project/11/interface/api/1067
export function getContentList(params: object): Promise<ApiList> {
  return phpApi({
    method: 'GET',
    url: `/h5/content/lists`,
    params
  })
}

// 直播列表
export function getAliveList(params: object): Promise<ApiList> {
  return phpApi({
    method: 'GET',
    url: `/h5/content/alive/lists`,
    params
  })
}
