



















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { getUserInfo, getPcSchoolDetail } from '../api'
import { linkTo } from '@/utils/utils.ts';

@Component({
})
export default class ComponentNav extends Vue {
  @Prop({ default: [] }) navs!: Array<object>
  //  监听路由变化，为导航栏添加样式
  @Watch('$route')
  routeupdate(to: any, from: any) {
    if (to.fullPath === '/') {
      this.active = 1
    } else {
      this.active = 0
    }
    this.headNavigation.forEach((r: any) => {
      console.log(r.dataset.i);
      if (to.fullPath.indexOf(r.dataset.i) !== -1) {
        r.className += ' active'
      } else {
        r.className = 'item-title'
      }
    });
  }
  top_navigation: boolean = false
  searchValue: string = ''
  active: number = 1 //初始化界面要给一个初始值，增加初始样式
  headNavigation: any
  toLink(link: any) {
    linkTo(this.$router, link, undefined)
  }
  topNavigation(e: any) {
    this.active = e;
  }
  mounted() {
    this.getPcSchoolDetail()
  }
  //  监听页面刷新，要保留样式
  updated() {
    this.headNavigation = this.$refs.let
    if (this.$route.path !== '/') {
      this.active = 0
    }
    this.headNavigation.forEach((r: any) => {
      console.log(r.dataset.i);
      if (this.$route.path.indexOf(r.dataset.i) !== -1) {
        r.className += ' active'
      } else {
        r.className = 'item-title'
      }
    });
  }
  toHome() {
    this.searchValue = ''
    if (this.$route.name === 'home') return
    this.$router.push({ name: 'home' })
  }
  getPcSchoolDetail() {
    getPcSchoolDetail({}).then(
      (res: any) => {
        this.top_navigation = res.top_navigation
      }
    ).catch(e => {
      console.log('e: ', e)
    })
  }
}
