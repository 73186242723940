




















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { isInServer } from '@/utils/utils'

@Component({
})
export default class PageWrap extends Vue {
  @Prop() loading!: boolean
  @Prop() errorObj!: any

  @Watch('loading', {immediate: true})
  onLoadingChanged(nVal: boolean) {
    if (isInServer()) {
      return
    }
    if (nVal) {
      window.scrollTo(0, 0)
    }
  }

  toHome() {
    this.$router.push({
      name: 'home'
    })
  }

  reload() {
    window.location.reload()
  }
}
