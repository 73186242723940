const routers = [
  {
    path: '/offline/detail/:id',
    name: 'OfflineDetail',
    component: () => import('@/modules/offlinecourse/pages/detail.vue')
  },
  {
    path: '/offline/list',
    name: 'OfflineList',
    component: () => import('@/modules/offlinecourse/pages/list.vue')
  }
]

export {
  routers
}
