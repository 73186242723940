const routers = [
  {
    path: '/limit/list',
    name: 'LimitList',
    component: () => import('@/modules/limit-purchase/pages/limit-list.vue')
  }
]

export {
  routers
}
