// 示例
// const config = {
//   shopId: 'gd3260d0jgb18g68b7',
//   env: 'pre',
//   host: 'ryupn-pc.pre.duanshu.com'
// }

const config = {
  shopId: '',
  env: '',
  host: ''
}

export default config
