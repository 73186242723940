import Vue from 'vue'
import Vuex from 'vuex'
// import { getShopInfo } from '@/modules/common/api'
// import * as model from '@/modules/common/models'
// import * as utils from '@/utils/utils'
import exam from './modules/exam'
import api from '@/api'
import envConfig from '@/config/env'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    exam
  },
  state: {
    denyVisit: false,
    testData: '',
    shopInfo: {},
    pcInfo: {},
    userInfo: {},
    // moduleInfo: {},
    shopId: '',
    req: {
      url: '',
      host: ''
    },
    errorInfo: {
      icon: '',
      message: ''
    },
    shopShowData: {},
    loginDialog: {
      m: '',
      visible: false
    },
    shop_config: {},
    privateInfo: {},
    $topHeader: null,
    // examList: [],
    // allCardList: [], // 全部题目的概况，【上一题】【下一题】切换时能定位到具体哪个题目
    // curSubjectInfo: {} // 当前题目
    fingerprint: ''
  },
  mutations: {
    'UPDATE_TEST_DATA': (stat, data) => {
      stat.testData = data
    },
    'SET_SHOP_INFO': (stat, data) => {
      console.log(stat, data, 'stat, datastat, data');
      
      stat.shopInfo = data
    },
    'SET_USER_INFO': (stat, data) => {
      stat.userInfo = data
    },
    'SET_SHOP_ID': (stat, data) => {
      stat.shopId = data
    },
    'SET_REQ': (stat, data) => {
      stat.req = data
    },
    'SET_PC_INFO': (stat, data) => {
      stat.pcInfo = data
    },
    'SET_PRIVATE_INFO': (stat, data) => {
      stat.privateInfo = data
    },
    'SET_ERROR_INFO': (stat, data) => {
      stat.errorInfo = data
    },
    'SET_TOPHEADER_COMPONENT': (stat, data) => {
      stat.$topHeader = data
    },
    'SET_DENY_VISIT': (stat, data) => {
      stat.denyVisit = data
    },
    'SET_FINGERPRINT': (stat, data) => {
      stat.fingerprint = data
    },
    'SET_SHOP_SHOW_DATA': (stat, shopData) => {
      // console.log('shopData: ', shopData)
      let [view, sub, update, price, free] = [true, true, true, false, false]
      let dataHidden = shopData.shop_info.data_hidden
      // console.log('pc_school: ', shopData.pc_school)
      let priceHidden = shopData.pc_school ? {
        'hidden_free': shopData.pc_school.hidden_free,
        'hidden_price': shopData.pc_school.hidden_price
      } : {
        'hidden_free': false,
        'hidden_price': false
      }
      let data: any = Object.assign(dataHidden, priceHidden)
      if (data) {
        // data 中 true 标识为隐藏 这里要反转一下
        view = !data.view
        sub = !data.subscribe
        update = !data.update
        price = !data.hidden_price
        free = !data.hidden_free
      }
      stat.shopShowData = {
        view, // 浏览数
        sub, // 订阅数
        update, // 更新数
        price, // 显示价格
        free // 显示“免费”
      }
    },
    'SET_LOGIN_DIALOG': (stat, {visible, m}) => {
      stat.loginDialog.visible = visible
      stat.loginDialog.m = m
    },
    'SET_SHOP_CONFIG': (stat, info) => {
      stat.shop_config = info
    },
    // 'SET_MODULE_INFO': (stat, data) => {
    //   stat.moduleInfo = data
    // },

    // 'SET_CUR_SUBJECY' (stat: any, payload: any) {
    //   store.state.curSubjectInfo = payload
    // },
    // 'SET_CARD_LIST' (stat: any, payload: any) {
    //   store.state.allCardList = payload
    // },
    // 'SET_EXAM_LIST' (stat: any, payload: any) {
    //   store.state.examList = payload
    // }
  },
  actions: {
    'GET_SHOP_INFO': async ({commit}, payload) => {
      console.log('GET_SHOP_ID start')
      let result = null
      // console.log('envConfig: ', envConfig)
      if (envConfig && envConfig!.host) {
        console.log(111);
        
        result = await api.getOSSShopId(envConfig!.host)
        // console.log('result: ', result)
      } else if (envConfig && envConfig!.shopId) { // 用于调试
        console.log(222);
        
        // console.log('debug shopId: ', envConfig!.shopId)
        commit('SET_SHOP_ID', envConfig!.shopId)
        let {h5_host} = await api.getShopInfo({shopId: envConfig!.shopId})
        h5_host = h5_host.replace('.duanshu.com', `.${envConfig!.env}.duanshu.com`)
        result = await api.getOSSShopId(h5_host)
        // console.log('result2', result)
      } else {
        console.log(333);
        
        console.log('GET_SHOP_ID pcHost: ', payload.pcHost)
        result = await api.getShopId(payload.pcHost)
        console.log('GET_SHOP_ID result: ', {
          shop_id: result.data.shop_id,
          h5_host: result.data.h5_host
        })
      }
      console.log(result, 'resultresult');
      
      if (!result.data.shop_id) {
        throw { code: 1001 }
      }
      if (result.data.shop_info) {
        commit('SET_SHOP_INFO', result.data.shop_info)
        commit('SET_SHOP_SHOW_DATA', result.data)
      }
      if (result.data.private_settings) {
        commit('SET_PRIVATE_INFO', result.data.private_settings)
      }
      commit('SET_SHOP_CONFIG', result.data)
      commit('SET_SHOP_ID', result.data.shop_id)
      return Promise.resolve()
    },
    'GET_PC_INFO': async ({commit}) => {
      const pcInfo = await api.getPcSchoolDetail({})
      commit('SET_PC_INFO', pcInfo)
      return Promise.resolve()
    },
    'GET_USER_INFO': async ({commit}) => {
      let userInfo = await api.getUserInfo()
      commit('SET_USER_INFO', userInfo)
      return userInfo
      // return getShopInfo().then((res: model.ShopInfo) => {
      //   commit('SET_SHOPINFO', res)
      // })
    },
    // 'GET_MODULES': async ({commit}) => {
    //   let moduleInfo = utils.storage.getStorage('moduleInfo', 'sessionStorage')
    //   if (!moduleInfo) {
    //     const modules = await api.getModules()
    //     for (let m of modules) {
    //       if (m && m.module.slug === 'web_school') {
    //         moduleInfo = m
    //         break
    //       }
    //     }
    //   }
    //   console.log('moduleInfo: ', moduleInfo)
    //   utils.storage.setStorage('moduleInfo', 'sessionStorage')
    //   commit('SET_MODULE_INFO', moduleInfo)
    //   return Promise.resolve()
    // }

    // // 更新列表中的某一条数据
    // setSubjectItem({state}: any, payload: any) {
    //   console.log(state.examList, 'state.examList');
    //   console.log(payload,'payload');
    //   const index = state.examList.findIndex((item: any) => item.childId === payload.childId)
    //   if (index === -1) {
    //     state.examList.push(payload)
    //   } else {
    //     state.examList.splice(index, 1, payload)
    //   }
    // },
    // resetStateData({ commit }: any) {
    //   console.log(commit,'commit');
    //   const resetMap: any = {
    //     'SET_CUR_SUBJECY': {},
    //     'SET_CARD_LIST': [],
    //     'SET_EXAM_LIST': []
    //   }
    //   // 执行重置操作
    //   Object.keys(resetMap).forEach(key => {
    //     // console.log(key, resetMap[key],'key, resetMap[key]');
    //     console.log(commit(key, resetMap[key]), 'commit(key, resetMap[key])');
    //     commit(key, resetMap[key])
    //   })
    // }
  },
  getters: {
    privateMode: state => {
      return (state.privateInfo as any).is_private === 1
    },
    enableCopyright(state) {
      let pcInfo: any = state.pcInfo
      return !!pcInfo.enabled_anti_theft
    }
  }
})

export default store

export function createStore() {
  return store
}
