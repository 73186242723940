const routers = [
  {
    path: '/studio/:id',
    name: 'studio',
    component: () => import('@/modules/studio/pages/detail/index.vue'),
    meta: {
      windowTitle: '直播间',
      needLogin: true
    }
  },
  {
    path: '/courseStudio/:id/:courseId',
    component: () => import('@/modules/studio/pages/detail/index.vue'),
    name: 'courseStudio',
    meta: {
      windowTitle: '课程直播间',
      needLogin: true
    }
  }
]

export {
  routers
}
