import uuidv4 from 'uuid/v4'
import pageConfig from './tracking-config'
import * as utils from '@/utils/utils'
import * as user from '@/utils/user'
import URI from 'urijs'

let formatConfig: any = getFormatConfig(pageConfig)

// 最后进入的页面
let lastPage: any = {}

export default {
  _getUrl(params: any = {}) {
    const envMap: any = {
      'release': 'production',
      'pre': 'prerelease',
      'test': 'develop'
    }
    const env: any = utils.getEnv()
    const shopId = utils.getShopId()
    const baseParams = {
      APIVersion: '0.6.0',
      platform: 'pc',
      channel: envMap[env] || envMap['release'],
      shop_id: shopId,
      '__topic__': shopId
    }
    let logLib = 'duanshu_tracking'
    let baseUrl = 'https://duanshu.cn-beijing.log.aliyuncs.com/logstores/{logLib}/track_ua.gif'
    if (params && params.logLib) {
      logLib = params.logLib
      delete params.logLib
    }
    baseUrl = baseUrl.replace('{logLib}', logLib)
    return new URI(baseUrl).addQuery(Object.assign(baseParams, params))
  },

  send(pageParams: any) {
    if (!pageParams) return
    let url = this._getUrl(pageParams)

    let img = new window.Image()
    img.src = url
  },

  enterPage(page: any, params: any) {
    let tid = uuidv4()
    let userId = user.getSign().id
    let actionParams = {
      event: 'view_page',
      action: 'enter',
      user_id: userId,
      uri: window.location.href,
      tid,
      page
    }
    let pageParams = Object.assign(actionParams, params)
    this.send(pageParams)
    lastPage = {
      params,
      ...actionParams,
      tid
    }
    return tid
  },

  leavePage(page = lastPage.page, params = lastPage.params, tid = lastPage.tid, uri = lastPage.uri) {
    let userId = user.getSign().id
    let actionParams = {
      event: 'view_page',
      action: 'leave',
      user_id: userId,
      uri,
      tid,
      page
    }
    let pageParams = Object.assign(actionParams, params)
    if (typeof page !== 'undefined') {
      this.send(pageParams)
    }
  },

  // 通过 route 中的参数 映射 相关统计参数
  pageFilter(route: any) {
    let page = ''
    let params: any = {}
    let routeName = route.name
    let pageConfig = formatConfig[routeName] || {}
    page = pageConfig.trackName
    let allParams = {
      ...route.params,
      ...route.query
    }

    if (pageConfig.routeParams) {
      for (let p of Object.keys(pageConfig.routeParams)) {
        let mapParam = pageConfig.routeParams[p]
        // 映射的是函数
        if (typeof mapParam === 'function') {
          params[p] = mapParam(route)
        } else {
        // 映射的是字符串
          params[mapParam] = allParams[p]
        }
      }
    }
    return {
      page,
      params
    }
  },

  routeEnter(route: any) {
    let options = this.pageFilter(route)

    // 之前进入过的页面 发出退出统计
    if (typeof lastPage.page !== 'undefined') {
      this.leavePage(lastPage.page, lastPage.params, lastPage.tid)
    }

    // 页面进入统计
    if (options.page) {
      this.enterPage(options.page, options.params)
    } else {
      this.enterPage('', {})
    }
  }
}

// 把 config 中的 routerName 变换 key
// 并 新加 key (trackName) 保存 原来的 key
// {
//  'abc':{
//    routerName:'ccc'
//  }
// }
// =>
// {
//  'ccc':{
//     routerName:'ccc',
//     trackName:'abc'
//  }
// }
function getFormatConfig(pageConfig: any) {
  let formatConfig: any = {}
  for (let n of Object.keys(pageConfig)) {
    let page = pageConfig[n]
    let routeName = page.routeName
    if (typeof routeName === 'string') {
      setPage(n, routeName, page)
    } else {
      for (let pageName of routeName) {
        setPage(n, pageName, page)
      }
    }
  }
  return formatConfig

  function setPage(trackName: any,pageName: any, page: any) {
    page.trackName = trackName
    formatConfig[pageName] = page
  }
}
