const getContentTypeSn = (contentType: string) => {
  switch (contentType) {
    case 'course':
      return '课程'
    case 'card':
      return '打卡'
    case 'community':
      return '小社群'
    case 'column':
      return '专栏'
    case 'member_card':
    case 'membercard':
      return '会员卡'
    case 'video':
      return '视频'
    case 'audio':
      return '音频'
    case 'article':
      return '图文'
    case 'live':
      return '直播'
    case 'offline':
    case 'offline_course':
    case 'offlinecourse':
      return '在线招生'
    case 'wechat_article':
      return '公众号文章'
    case 'aimicrolecture':
      return 'A.I.微课'
    case 'trainingcampclass':
      return '训练营'
    case 'provingground':
      return '试练场'
    case 'entity':
      return '实物商品'
    case 'smallclass':
      return '小班课'
    case 'repeatedpractice':
      return '智适应题库'
    case 'largeclass':
      return '系列直播'
    default:
      return '其他'
  }
}

const supportType = [
  'course',
  'video',
  'audio',
  'article',
  'live',
  'offlinecourse'
]

function isSupportType(type: string) {
  return supportType.indexOf(type) > -1
}

export {
  getContentTypeSn,
  isSupportType
}