const routers = [
  {
    path: '/category/list',
    name: 'categoryList',
    component: () => import('../pages/category-list.vue')
  },
  {
    path: '/category/content/list/:category_id',
    name: 'categoryContentList',
    component: () => import('../pages/category-content-list.vue')
  }
]

export {
  routers
}
