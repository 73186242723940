















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Dialog } from 'element-ui'
import dsIcon from '@/modules/common/components/ds-icon.vue'
// const QRCode = require('@keeex/qrcodejs-kx')
const uuidv4 = require('uuid/v4')

@Component({
  components: {
    [Dialog.name]: Dialog,
    dsIcon
  }
})
export default class QrcodePop extends Vue {
  qrcodeId: string = uuidv4()
  qrcodeText: string = ''
  title: string = '请前往h5店铺'
  visible: boolean = true
  mounted() {
    this.$nextTick(() => {
      const QRCode = require('@keeex/qrcodejs-kx')
      let qrcode = new QRCode(this.qrcodeId, {
        width: 210, // 设置宽度，单位像素
        height: 210, // 设置高度，单位像素
        text: this.qrcodeText // 设置二维码内容或跳转地址
      })
    })
  }
}
