
export const time_utils = {
  getNowStamp: () => {
    return new Date().getTime()
  },

  getTimeStamp: (time: string | null | undefined) => {
    if (!time) {
      return time_utils.getNowStamp()
    }
    return +new Date(time)
  },

  formatDate: (value: any, fmt?: string) => {
    if (fmt === undefined || !fmt.length) {
      fmt = 'yyyy-MM-dd hh:mm'
    }
    let date
    try {
      if (isNaN(value)) {
        // "2018-12-26T01:54:34.136110+00:00"
        // 上面日期 把 - 换成 /       在安卓/ios 11.2 会变 Nan
        // 2018-12-26 01:54:34 ios 11.2 不换成 / 也能正常解释
        if (value.indexOf('T') === -1) {
          value = value.replace(/-/g, '/')
        }
        date = new Date(value)
      } else {
        date = new Date(value.toString().length == 13 ? value : value * 1000)
      }
    } catch (e) {
      return value
    }
    if (isNaN(date.getMonth())) date = new Date(Number(value))
    let o: any = {
      'M+': date.getMonth() + 1, // 月份
      'd+': date.getDate(), // 日
      'h+': date.getHours(), // 小时
      'm+': date.getMinutes(), // 分
      's+': date.getSeconds(), // 秒
      'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
      S: date.getMilliseconds() // 毫秒
    }
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        (date.getFullYear() + '').substr(4 - RegExp.$1.length)
      )
    }
    for (let k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
        )
      }
    }
    return fmt
  },

  operateTime: (time:any) => {
    return time < 10 ? '0' + time : time
  },

  /**
  * 时间间隔 counterTime
  */
  counterTime: (endTime: any) => {
    if (typeof endTime == 'number') {
      endTime = new Date(endTime);
    } else if (isNaN(Date.parse(endTime))) {
      endTime = new Date(Date.parse(endTime.replace(/-/g, '/')));
    } else {
      endTime = new Date(Date.parse(endTime));
    }

    const now:any = new Date();
    let range = (endTime - now) / 1000
    let counter:any = {}
    if (range < 0) {
      range = 0 - range;
      counter.valid = false;
    } else {
      counter.valid = true;
    }
    counter.day = time_utils.operateTime(Math.floor(range / (24 * 60 * 60)));
    counter.hour = time_utils.operateTime(Math.floor(range % (24 * 60 * 60) / (60 * 60)));
    counter.min = time_utils.operateTime(Math.floor(range % (24 * 60 * 60) % (60 * 60) / 60));
    counter.sec = time_utils.operateTime(Math.floor(range % (24 * 60 * 60) % (60 * 60) % 60));
    return counter;
  }
}
