import { createApp } from './main'
import Vue from 'vue'
import * as User from '@/utils/user'

const { app, router, store } = createApp()

declare global {
  interface Window {
    __INITIAL_STATE__: any;
    [key: string]: any
  }
}

Vue.mixin({
  beforeRouteUpdate (to, from, next) {
    // next()
    const { asyncData } = (this as any).$options
    if (asyncData) {
      // 将获取数据操作分配给 promise
      // 以便在组件中，我们可以在数据准备就绪后
      // 通过运行 `this.dataPromise.then(...)` 来执行其他任务
      asyncData({
        store: store,
        route: to
      }).then(next).catch(next)
    } else {
      next()
    }
  }
})

router.onReady(() => {
  // 添加路由钩子函数，用于处理 asyncData.
  // 在初始路由 resolve 后执行，
  // 以便我们不会二次预取(double-fetch)已有的数据。
  // 使用 `router.beforeResolve()`，以便确保所有异步组件都 resolve。
  router.beforeResolve((to, from, next) => {
    const matched = router.getMatchedComponents(to)
    const prevMatched = router.getMatchedComponents(from)
    // 我们只关心之前没有渲染的组件
    // 所以我们对比它们，找出两个匹配列表的差异组件
    let diffed = false
    const activated = matched.filter((c, i) => {
      return diffed || (diffed = (prevMatched[i] !== c))
    })
    if (!activated.length) {
      return next()
    }
    // 这里如果有加载指示器(loading indicator)，就触发
    Promise.all(activated.map((component: any) => {
      if (component.asyncData) {
        return component.asyncData({ store, route: to })
      }
    })).then(() => {
      // 停止加载指示器(loading indicator)
      next()
    }).catch(next)
  })

  app.$mount('#app')
  // new Promise((resolve, reject) => {
  //   let sign = User.checkSign()
  //   // 没 sign 才启动应用
  //   if (!sign) {
  //     resolve()
  //   }
  // })
  // .then(() => {
  //   app.$mount('#app')
  // }).catch(() => {
  //   app.$mount('#app')
  // })
})
