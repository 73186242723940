/* eslint-disable */
const state = {
  examList: [],
  allCardList: [], // 全部题目的概况，【上一题】【下一题】切换时能定位到具体哪个题目
  curSubjectInfo: {}, // 当前题目
  examDetail: {} // 当前题目详情
};

const mutations = {
  ['SET_CUR_SUBJECY'] (state: any, payload: any) {
    console.log(state, payload, 'payloadpayloadpayload');
    
    state.curSubjectInfo = payload
  },
  ['SET_CARD_LIST'] (state: any, payload: any) {
    state.allCardList = payload
  },
  ['SET_EXAM_LIST'] (state: any, payload: any) {
    state.examList = payload
  },
  ['SET_EXAM_DETAIL'] (state:any, payload: any) {
    state.examDetail = payload
  }
};

const getters = {};

const actions = {
  // 更新列表中的某一条数据
  setSubjectItem({state}: any, payload: any) {
    const index = state.examList.findIndex((item: any) => item.childId === payload.childId)
    if (index === -1) {
      state.examList.push(payload)
    } else {
      state.examList.splice(index, 1, payload)
    }
  },
  resetStateData({ commit }: any) {
    const resetMap: any = {
      'SET_CUR_SUBJECY': {},
      'SET_CARD_LIST': [],
      'SET_EXAM_LIST': []
    }
    // 执行重置操作
    Object.keys(resetMap).forEach(key => {
      commit(key, resetMap[key])
    })
  }
};

export default {
  state,
  mutations,
  getters,
  actions
};
