import Vue from 'vue'
import Router from 'vue-router'
import Store from '@/store'
import Fingerprint2 from 'fingerprintjs2'
import { routers as common } from '@/modules/common/router'
import { routers as exam } from '@/modules/exam/router'
import { routers as content } from '@/modules/content/router'
import { routers as course } from '@/modules/course/router'
import { routers as category } from '@/modules/category/router'
import { routers as pintuan } from '@/modules/pintuan/router'
import { routers as limitPurchase } from '@/modules/limit-purchase/router'
import { routers as studio } from '@/modules/studio/router'
import { routers as offlinecourse } from '@/modules/offlinecourse/router'
import { routers as columnDetail } from '@/modules/column/router'
import { checkLogin, toLogin } from '@/utils/user'
import track from './track/tracking'
import {isInServer} from '@/utils/utils';

Vue.use(Router)

const router = new Router({
  mode: 'history'
  // base: process.env.BASE_URL,
})
router.addRoutes(common)
router.addRoutes(exam)
router.addRoutes(content)
router.addRoutes(course)
router.addRoutes(category)
router.addRoutes(pintuan)
router.addRoutes(limitPurchase)
router.addRoutes(studio)
router.addRoutes(offlinecourse)
router.addRoutes(columnDetail)

router.beforeEach((to:any, from, next) => {
  // 拒绝访问 只能进入错误页
  if (to.name !== 'error' && Store.state.denyVisit) {
    return next({ name: 'error' })
  }
  Fingerprint2.get({}, function (components) {
    const values = components.map(function (component) {
      return component.value
    })
    const fingerprint = Fingerprint2.x64hash128(values.join(''), 31)
    Store.commit('SET_FINGERPRINT', fingerprint)
    return fingerprint
  })
  // 非服务渲染 && 页面需要登录 && 本地没登录信息
  // 必须先检测是否服务端渲染 不然 checkLogin 调 localStorage 会报错
  // 私密登录开启下 除了 系统页 所有页面都需要登录态
  let needLogin = !to.meta.systemPage && (Store.getters.privateMode || to.meta.needLogin)
  if (!Vue.prototype.$isServer && needLogin && !checkLogin()) {
    let { href } = router.resolve(to as any)
    let successUrl = `${window.location.origin}${href}`
    toLogin(successUrl)
    return next(false)
  }
  next()
})

router.afterEach((route) => {
  if (!isInServer()) {
    track.routeEnter(route)
  }
})

export default router

export function createRouter() {
  return router
}
