







































import { Component, Vue, Prop } from 'vue-property-decorator'
import { getOSSCopyright, getDomain, getCopyright } from '@/modules/common/api/index.ts'
@Component({
  components: {

  }
})
export default class ComponentBottomFooter extends Vue {
  copyright: object = {}
  icp: string= ''
  copyrights: object = {}
  showTech: boolean = false
  url: string = 'http://www.beian.gov.cn/portal/registerSystemInfo'
  domain: object = {
    gswj: false,
    icp: '',      
    icp_logo: '',
    icp_show: false,
    gswj_url: '',
    icp_num: '',
  }
  mounted() {
    this.getCopyrights()
    this.getDomainlist()
    this.getCopyrightlist()
  }
  getDomainlist() {
    getDomain().then(res => {
      if (res.data == '') {
        this.domain = {
          gswj: false,
          icp: '',      
          icp_logo: '',
          icp_show: false,
          gswj_url: '',
          icp_num: '',
        }
      }else {
        this.domain = res.data[0]
        this.icp = res.data[0].icp
        let value = this.icp.split(' ')
        this.url = this.url + '?' + 'recordcode=' + value[1]
      }
    }).catch(() => {
    })
  }
  getCopyrightlist() {
    getCopyright().then(res => {
      if (res.data.length == 0) {
        this.copyrights = {
          show_copyright: true, 
          show_technical_support: true,
          copyright: '',
          technical_support: ''
        }
      }else {

        this.copyrights = res.data[0]
      }
      
    }).catch(() => {
    })
  }
  getCopyrights() {
    getOSSCopyright().then(res => {
      this.copyright = res.data
    }).catch(() => {
      this.copyright = {
        name: '©2015南京厚建云计算有限公司《厚建云计算隐私权政策》',
        icp: 'ICP证：苏ICP备16026135号'
      }
    })
  }
}
