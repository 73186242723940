import { Button, MessageBox, Card, Pagination , Message, Input, Select, Option, Cascader ,CheckboxGroup, Checkbox, Upload, DatePicker,Dialog, TimePicker } from 'element-ui'
import Vue from 'vue'
import VueMeta from 'vue-meta'
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import Filters from './utils/filters'
import { createRouter } from './router'
import { createStore } from './store'
import * as User from '@/utils/user'
import * as Utils from '@/utils/utils'
import DsIcon from '@/modules/common/components/ds-icon.vue'
import QrcodePop from '@/modules/common/components/qrcode-pop.vue'
import { Component } from 'vue-property-decorator'
import PageWrap from '@/modules/common/components/page-wrap.vue'




Vue.config.productionTip = false

Component.registerHooks([
  'beforeRouteUpdate',
  'beforeDestroy'
])

export function createApp () {
  const router = createRouter()
  const store = createStore()
  let app

  // router beforeEach 取不了 shop_id
  // store 初始化调整到这
  if (typeof window !== 'undefined' && window.__INITIAL_STATE__) {
    store.replaceState(window.__INITIAL_STATE__)
  }
  var COS = require('cos-js-sdk-v5');

  Vue.use(VueMeta)
  Vue.use(Filters)
  Vue.use(Option)
  Vue.use(CheckboxGroup)
  Vue.use(Checkbox)
  Vue.use(Upload)
  Vue.use(DatePicker)
  Vue.use(Dialog)
  Vue.use(TimePicker)


  Vue.component('DsIcon', DsIcon)
  Vue.component('PageWrap', PageWrap)

  Vue.prototype.$message = (opts: any) => {
    (Message as any).closeAll()
    Message.call(Vue, opts)
  }

  Vue.use(Button)
  Vue.use(Card)
  Vue.prototype.$msgbox = MessageBox;
  Vue.prototype.$alert = MessageBox.alert;
  Vue.prototype.$confirm = MessageBox.confirm;
  Vue.prototype.$prompt = MessageBox.prompt;

  Vue.use(Input)
  Vue.use(Select)
  Vue.use(Cascader)
  Vue.use(Pagination)

  // 创建一个自定义指令，用于阻止复制事件
  Vue.directive('prevent-copy', {
    bind: function (el) {
      el.addEventListener('copy', function (e) {
        e.preventDefault();
        alert('不允许复制文本！');
      });
    }
  });

  Vue.prototype.$showH5qrCode = (route: any, title?: string, type: string = 'vue') => {
    if (Vue.prototype.$isServer) return
    let qrcodeText = typeof route === 'string' ? route : Utils.getH5Url(route, type)
    let instance
    let data: any = { qrcodeText }
    if (title) {
      data.title = title
    }
    instance = new QrcodePop({ data })
    instance.$mount()
    document.body.appendChild(instance.$el)
    return instance
  }

  app = new Vue({
    router,
    store,
    render: h => h(App),
    components: {
      DsIcon
    }
  })

  return { app, router, store, COS }
}


