





















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Popover } from 'element-ui'
import dsIcon from '@/modules/common/components/ds-icon.vue';
const uuidv4 = require('uuid/v4')

@Component({
  components: {
    [Popover.name]: Popover,
    dsIcon
  }
})
export default class SweepCodePopover extends Vue {
  @Prop()
  imgUrl!: string
  @Prop()
  qrcodeUrl!: string
  @Prop()
  content!: Array<object>

  qrcodeId: string = uuidv4()

  @Watch('qrcodeUrl', { immediate: true })
  onQrcodeUrlChanged(val: string, oldVal: string) {
    if (val) {
      this.$nextTick(() => {
        const QRCode = require('@keeex/qrcodejs-kx')
        if (document.getElementById(this.qrcodeId)) {
          let qrcode = new QRCode(this.qrcodeId, {
            width: 149,
            height: 149,
            text: val
          });
        }
      })
    }
  }
}
