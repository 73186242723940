import { utils } from '@/utils/utils.ts'
import { time_utils } from '@/utils/time_utils'
import { getContentTypeSn } from'@/config/typeConfig.ts'

export default {
  install(Vue: any, options = {}) {

    Vue.filter('numberFormat', (v: number) => {
      let num = v
      let text: string
      if (num < 1000) {
        text = num.toString()
      } else if (num >= 1000 && num < 10000) {
        let n = (num / 1000).toString()
        n = n.slice(0, n.indexOf('.') + 2)
        text = `${n}k`
      } else {
        let n = (num / 10000).toString()
        n = n.slice(0, n.indexOf('.') + 2)
        text = `${n}w`
      }
      return text
    })

    Vue.filter('formatDate', time_utils.formatDate)

    Vue.filter('createImgSrc', utils.createImgSrc)

    Vue.filter('typeLabel', getContentTypeSn)
  }
}
