




import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({
  
})
export default class dsIcon extends Vue {
  @Prop({ default: '' }) code!: string
  @Prop() fontSize?: number | undefined
  @Prop() color?: string | undefined
  get style() {
    return { 'font-size': this.fontSize ? `${this.fontSize}px` : undefined, 'color': this.color }
  }
}
