



















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import dsIcon from '@/modules/common/components/ds-icon.vue';
import sweepCodePopover from '@/modules/common/components/sweep-code-popover.vue';
import {getMicroCode, wechatService} from '@/modules/common/api'

@Component({
  components: {
    sweepCodePopover,
    dsIcon
  }
})

export default class ComponentRightBar extends Vue {
  toTopShow: boolean = false
  scrollTop: number = 0
  barItemList: Array<object> = [
    { name: '公众号', code: '&#xe6ae;', imgUrl: '' },
    { name: '联系客服', code: '&#xe6aa;', imgUrl: '' },
    { name: 'H5店铺', code: '&#xe6ac;', qrcodeUrl: '' },
    { name: '小程序', code: '&#xe6ad;', imgUrl: '' },
    { name: '联系我们', code: '&#xe713;', content: null }
  ]
  get barList() {
    return this.barItemList.filter((item: any) => item.imgUrl || item.qrcodeUrl || item.content)
  }
  get shopInfo() {
    return this.$store.state.shopInfo
  }
  get pcInfo() {
    return this.$store.state.pcInfo
  }
  @Watch('shopInfo', {immediate: true})
  onShopInfoChanged(nVal: any) {
    let publicObj: any = nVal.public
    if (publicObj && publicObj.qrcode) {
      this.updateImgUrl(0, publicObj.qrcode)
    }

    this.$nextTick(() => {
      if (nVal.h5_base_uri) {
        this.updateQrcodeUrl(2, nVal.h5_base_uri)
      }
    })
  }
  mounted() {
    this.getData()
    this.$nextTick(() => {
      window.addEventListener('scroll', this.handleScroll, true)
    })
    this.setContact()
  }
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll, true);
  }
  handleScroll() {
    this.toTopShow = !!(window.scrollY > 300)
    this.scrollTop = window.scrollY
  }
  scrollToTop() {
    let timer: number = 0
    let that = this
    cancelAnimationFrame(timer)
    timer = requestAnimationFrame(function fn() {
      if (window.scrollY > 5000) {
        that.scrollTop -= 1000
        window.scrollTo(0, that.scrollTop)
        timer = requestAnimationFrame(fn)
      } else if (window.scrollY > 1000 && window.scrollY <= 5000) {
        that.scrollTop -= 500
        window.scrollTo(0, that.scrollTop)
        timer = requestAnimationFrame(fn)
      } else if (window.scrollY > 200 && window.scrollY <= 1000) {
        that.scrollTop -= 100
        window.scrollTo(0, that.scrollTop)
        timer = requestAnimationFrame(fn)
      } else if (window.scrollY > 50 && window.scrollY <= 200) {
        that.scrollTop -= 10
        window.scrollTo(0, that.scrollTop)
        timer = requestAnimationFrame(fn)
      } else if (window.scrollY > 0 && window.scrollY <= 50) {
        that.scrollTop -= 5
        window.scrollTo(0, that.scrollTop)
        timer = requestAnimationFrame(fn)
      } else {
        cancelAnimationFrame(timer)
        that.toTopShow = false
      }
    })
  }
  getData() {
    getMicroCode({
      slug: 'index'
    }).then((e: any) => {
      if (!e.error) {
        e.qrcode_url && this.updateImgUrl(3, e.qrcode_url)
      }
    })

    wechatService().then((e: any) => {
      if (!e.error) {
        let data = e.wechat_bot
        data && data.qrcode && this.updateImgUrl(1, data.qrcode)
      }
    })
  }
  updateImgUrl(i: number, url: string) {
    let idx = i
    let item: any = this.barItemList[idx]
    item.imgUrl = url
    this.barItemList.splice(idx, 1, item)
  }
  updateQrcodeUrl(i: number, url: string) {
    let idx = i
    let item: any = this.barItemList[idx]
    item.qrcodeUrl = url
    this.barItemList.splice(idx, 1, item)
  }
  setContact() {
    if (!this.pcInfo.enabled_contact_us) return
    let info = this.shopInfo
    let item: any = this.barItemList[4]
    let content: Array<object> = [
      {
        title: '联系电话',
        brief: info.telephone
      },
      {
        title: '详细地址',
        brief: info.address
      },
      {
        title: '关于我们',
        brief: info.brief
      }
    ]
    if (info.email) {
      content.splice(1, 0, {
        title: '邮箱',
        brief: info.email
      })
    }
    item['content'] = content
    this.barItemList.splice(4, 1, item)
  }
}
