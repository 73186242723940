const routers = [
  {
    path: '/',
    name: 'home',
    // component: (resolve: any) =>  require(['@/modules/common/pages/home.vue'], resolve)
    component: (resolve: any) => require(['../pages/home.vue'], resolve)
  },
  {
    path: '/subscribe',
    name: 'subscribe',
    component: (resolve: any) => require(['../pages/subscribe.vue'], resolve)
  },
  {
    path: '/search/',
    name: 'search',
    component: (resolve: any) => require(['../pages/search.vue'], resolve)
  },
  {
    path: '/404',
    name: '404',
    component: (resolve: any) => require(['../pages/404.vue'], resolve),
    meta: {
      systemPage: true,
      hideHeader: true,
      hideRightBar: true,
      hideFooter: true
    }
  },
  {
    path: '/error',
    name: 'error',
    component: (resolve: any) => require(['../pages/error.vue'], resolve),
    meta: {
      systemPage: true,
      hideHeader: true,
      hideRightBar: true,
      hideFooter: true
    }
  },
  {
    path: '/closed',
    name: 'closed',
    component: (resolve: any) => require(['../pages/closed.vue'], resolve),
    meta: {
      systemPage: true,
      hideHeader: true,
      hideRightBar: true,
      hideFooter: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: (resolve: any) => require(['../pages/login.vue'], resolve),
    meta: {
      systemPage: true,
      hideHeader: true,
      hideRightBar: true,
      hideFooter: true
    }
  }
]

export {
  routers
}
