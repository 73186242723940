const routers = [
  {
    path: '/exam/:id',
    name: 'exam',
    component: () => import('@/modules/exam/page/index.vue'),
    meta: {
      needLogin: true,
      windowTitle: '测验'
    },
    props: (route: any) => ({
      id: route.params.id, // 试卷id
      curSubId: route.query.curSubId, // 题目id
      renderType: route.query.renderType, // exam、analysis  渲染类型
      examType: route.query.examType, // exam、practice  试卷类型
      contentId: route.query.contentId, // 内容id
      contentType: route.query.contentType // 内容type
    })
  },
  {
    path: '/exam/result/:id',
    name: 'examResult',
    component: () => import('@/modules/exam/page/exam-result.vue'),
    meta: {
      needLogin: true,
      windowTitle: '考试结果'
    },
    props: (route: any) => ({
      id: route.params.id, // 试卷id
      contentId: route.query.contentId, // 内容id
      contentType: route.query.contentType // 内容type
    })
  },
  {
    path: '/exam/analysis/:id',
    name: 'examAnalysis',
    component: () => import('@/modules/exam/page/exam-analysis.vue'),
    meta: {
      needLogin: true,
      windowTitle: '解析详情'
    },
    props: (route:any) => ({
      id: route.params.id // 试卷id
    })
  },
  // {
  //   path: '/course/detail/:id',
  //   name: 'course',
  //   component: () => import('@/modules/course/pages/course-detail/index.vue')
  // },
  // {
  //   path: '/course/class/:courseId/:cId',
  //   name: 'courseClass',
  //   component: () => import('@/modules/course/pages/course-class/index.vue'),
  //   meta: {
  //     needLogin: true
  //   }
  // },
  // {
  //   path: '/exam/detail/:id',
  //   name: 'examDetail',
  //   component: () => import('@/modules/course/pages/course-exam/index.vue'),
  //   meta: {
  //     showHeaderBar: true,
  //     needLogin: true,
  //     windowTitle: '测验详情',
  //     noShare: true
  //   },
  //   props: (route: any) => ({
  //     id: route.params.id, // 试卷id
  //     type: route.query.type, // 内容的类型
  //     contentId: route.query.contentId, // 内容的id
  //     cId: route.query.cId
  //   })
  // },
  // {
  //   path: '/course/list',
  //   name: 'courseList',
  //   component: () => import('@/modules/course/pages/course-list.vue')
  // },
  // {
  //   path: '/course/material/:courseId/:mId',
  //   name: 'courseMaterial',
  //   component: () => import('@/modules/course/pages/material-detail.vue')
  // }
]

export {
  routers
}
