const routers = [
  {
    path: '/pintuan/list',
    name: 'PintuanList',
    component: () => import('@/modules/pintuan/pages/pintuan-list.vue')
  }
]

export {
  routers
}
