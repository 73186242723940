
















import { Component, Vue } from 'vue-property-decorator'
import topHeader from '@/modules/common/components/top-header.vue'
import bottomFooter from '@/modules/common/components/bottom-footer.vue'
import rightBar from '@/modules/common/components/right-bar.vue'
import api from '@/api'
import CommonLoginDialog from '@/modules/common/components/login-dialog.vue'

@Component({
  components: {
    CommonLoginDialog,
    topHeader,
    bottomFooter,
    rightBar
  },
  metaInfo() {
    return {
      title: this.$store.state.pcInfo.title || this.$store.state.shopInfo.title || '',
      link: [
        {
          vmid: 'icon',
          rel: 'shortcut icon',
          href: this.$store.state.pcInfo.ico || '/facicon.ico'
        }
      ],
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.$store.state.pcInfo.description || this.$store.state.shopInfo.brief || ''
        },
        {
          vmid: 'keywords',
          name: 'keywords',
          content: this.$store.state.pcInfo.key_words || ''
        },
      ]
    }
  }
})
export default class App extends Vue {
  get hideHeader() {
    if(this.$route.meta)
    return this.$route.meta.hideHeader
  }

  get hideRightBar() {
    if(this.$route.meta)
    return this.$route.meta.hideRightBar
  }

  get hideFooter() {
    if(this.$route.meta)
    return this.$route.meta.hideFooter
  }

  get loginDialogVisible() {
    return this.$store.state.loginDialog.visible
  }

  get shopInfo() {
    return this.$store.state.shopInfo
  }

  mounted() {
    // console.log('有没有进入mounted');
    
    this.checkModule()
    // this.$store.dispatch('GET_PC_INFO')

    this.$store.commit('SET_TOPHEADER_COMPONENT', this.$refs.topHeader)
    // console.log('pcInfo: ', this.$store.state.pcInfo)
  }

  checkModule() {
    api.getModules().then(res => {
      const modules: any = res
      let pcModule = null
      for (let m of modules.data) {
        if (m && m.module.slug === 'web_school') {
          pcModule = m
          break
        }
      }     
      const isOpen = pcModule && pcModule.status === 2     
      const isExpire = pcModule && pcModule.is_expire
      const hasVisitRight = isOpen && !isExpire     
      if (!hasVisitRight) {
        console.log('有没有进入if判断');
        
        this.$store.commit('SET_DENY_VISIT', true)
        this.$store.commit('SET_ERROR_INFO', { message: '抱歉，该页面已失效，暂时无法访问' })
        this.$router.replace({
          name: 'error'
        })
      }
    }).catch(e => {
      if (e.response && e.response.status !== 401) {
        this.$store.commit('SET_DENY_VISIT', true)
        this.$store.commit('SET_ERROR_INFO', { message: '获取模块失败' })
        this.$router.replace({
          name: 'error'
        })
      }
    })
  }
}
