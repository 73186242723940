// 去登录
import { getEnv, getH5Url, cookie } from '@/utils/utils'
import store from '@/store'
import router from '@/router'
import URI from 'urijs'

export const parseSign = (url: string) => {
  const query: any = URI(url).query(true)
  const sign = query && query.sign
  if (sign) {
    const redirectUrl = URI(url).removeQuery('sign').toString()
    return {
      sign,
      redirectUrl
    }
  }
  return ''
}

export const addSign = (url: string, sign: any) => {
  const signString = encodeURIComponent(JSON.stringify(sign))
  const uri: any = URI(url).addQuery('sign', signString)
  return uri.toString()
}

export const decodeSign = (sign: string) => {
  // const userData = JSON.parse(decodeURIComponent(decodeURIComponent(sign)))
  const userData = decodeURIComponent(decodeURIComponent(sign))
  return userData
}

// 检测 url 中的 sign 并写入 storage
// export let checkSign = (url: string) => {
//   // let href = window.location.href
//   let sign = parseSign(url)
//   // let sign = (query as any).sign
//   if (sign && set) {
//     let data = JSON.parse(decodeURIComponent(sign))
//     let replaceUrl = URI(href).removeQuery('sign').toString()
//     setSign(data)
//     window.location.replace(replaceUrl)
//   }
//   return sign
// }

// 去登录
export let toLogin = (successUrl?: String, method?: String) => {
  let returnUrl = successUrl || window.location.href
  if (store.getters.privateMode) {
    let routeObj: any = {
      name: 'login',
      query: {
        successUrl
      }
    }
    if (!method) {
      router.push(routeObj)
    } else {
      router.replace(routeObj)
    }
  } else {
    toH5Login(returnUrl, method)
  }
}

export let toH5Login = (successUrl?: String, method?: String) => {
  let h5Route = {
    name: 'SystemLogin',
    query: {
      from: 'pc',
      successUrl
    }
  }
  let url = getH5Url(h5Route)
  if (!method) {
    window.location.href = url
  } else {
    window.location.replace(url)
  }
}

// 去注册
export let toReg = (successUrl?: String) => {
  let returnUrl = successUrl || window.location.href
  let h5Route = {
    name: 'SystemLogin',
    query: {
      from: 'pc',
      formType: 'reg',
      successUrl: returnUrl
    }
  }
  let url = getH5Url(h5Route)
  window.location.href = url
}

// 检测登录态
export let checkLogin = () => {
  let sign: any = getSign()
  let flag = Boolean(sign.id)
  if (store.getters.privateMode) {
    flag = sign && sign.source === 'inner'
  }
  return flag
}

export let getSignCacheKey = () => {
  return ['userInfo', getEnv()].join('_')
}

// 改为由服务端写入
// export let setSign = (data: any) => {
//   let expire = data.expire - data.timestamp
//   let cur = (new Date()).getTime()
//   let cacheKey = getSignCacheKey()
//   expire = Math.floor(cur / 1000) + expire
//   // storage.setStorage(cacheKey, data, expire)
// }

// 取登录态
export let getSign = () => {
  const cacheKey = getSignCacheKey()
  const userDataStr: any = cookie.get(cacheKey)
  let userData: any = {}
  try {
    if (userDataStr) {
      userData = JSON.parse(userDataStr)
    }
  } catch (e) {}
  // let sign = storage.getStorage(cacheKey)
  return userData
}

// 清除登录态
export let removeSign = () => {
  let cacheKey = getSignCacheKey()
  // storage.removeStorage(cacheKey)
  cookie.set(cacheKey, null, {
    path: '/'
  })
}

// 退出登录
export let logout = () => {
  removeSign()
}
