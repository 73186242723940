







































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Input, Popover } from 'element-ui'
import dsIcon from '@/modules/common/components/ds-icon.vue'
import { getUserInfo, getPcSchoolDetail } from '../api'
import * as api from '../api'
import * as User from '@/utils/user'
import topNavigation from '../components/topNavigation.vue'

interface NavModel {
  title?: string
  brief?: string
  index_pic?: string
  state?: number
}

interface UserModel {
  uid?: string
  nick_name?: string
  avatar?: string
}
@Component({
  components: {
    [Input.name]: Input,
    [Popover.name]: Popover,
    dsIcon,
    topNavigation
  }
})
export default class ComponentTopHeader extends Vue {
  searchValue: string = ''
  logo: string = ''
  navs: Array<NavModel> = []
  userInfo: UserModel = {
    uid: '',
    nick_name: '',
    avatar: ''
  }
  created() {

  }
  mounted() {
    if (User.checkLogin()) {
      this.getUserInfo()
    }
    this.getPcSchoolDetail()
    this.getTopNavigationList()
  }
  getTopNavigationList() {
    let params: object = {
      'type': 'web',
      'platform': 'pc_top'
    }
    api.getTopNavigationList(params).then(
      res => {
        this.navs = res.data.filter((item: any) => item.state == 1)
      }
    ).catch(e => {
      console.log('e: ', e)
    })
  }
  getPcSchoolDetail() {
    getPcSchoolDetail({}).then(
      (res: any) => {
        if(!(this.userInfo && this.userInfo.uid) && !res.vistor_mode) {
          this.toLogin()
        }
        this.logo = res.logo
      }
    ).catch(e => {
      console.log('e: ', e)
    })
  }
  toSearch() {
    this.$router.push({ name: 'search', query: { keyword: this.searchValue } })
  }
  toHome() {
    this.searchValue = ''
    if (this.$route.name === 'home') return
    this.$router.push({ name: 'home' })
  }
  toLogin() {
    let successUrl = window.location.href   
    User.toLogin(successUrl)
  }
  toReg() {
    let successUrl = window.location.origin
    User.toReg(successUrl)
  }
  toLogout() {
    User.logout()
    if (this.$route.name === 'home') {
      location.reload()
    } else {
      location.href = '/'
    }
  }
  toSubscribe() {
    this.$router.push({ name: 'subscribe' })
  }
  getUserInfo() {
    getUserInfo().then(res => {
      console.log('userInfo: ', res)
      this.$store.commit('SET_USER_INFO', res)
      this.userInfo = res
    }).catch(e => {
      console.log('e: ', e)
    })
  }
  emptySerachValue() {
    this.searchValue = ''
  }
}
