const routers = [
  {
    path: '/course/detail/:id',
    name: 'course',
    component: () => import('@/modules/course/pages/course-detail/index.vue')
  },
  {
    path: '/course/class/:courseId/:cId',
    name: 'courseClass',
    component: () => import('@/modules/course/pages/course-class/index.vue'),
    meta: {
      needLogin: true
    }
  },
  {
    path: '/exam/detail/:id',
    name: 'examDetail',
    component: () => import('@/modules/course/pages/course-exam/index.vue'),
    meta: {
      showHeaderBar: true,
      needLogin: true,
      windowTitle: '测验详情',
      noShare: true
    },
    props: (route: any) => ({
      id: route.params.id, // 试卷id
      type: route.query.type, // 内容的类型
      contentId: route.query.contentId, // 内容的id
      cId: route.query.cId
    })
  },
  {
    path: '/course/list',
    name: 'courseList',
    component: () => import('@/modules/course/pages/course-list.vue')
  },
  {
    path: '/course/material/:courseId/:mId',
    name: 'courseMaterial',
    component: () => import('@/modules/course/pages/material-detail.vue')
  }
]

export {
  routers
}
