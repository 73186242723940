const routers = [
  {
    path: '/column/detail/:id',
    name: 'columnDetail',
    component: () => import('@/modules/column/pages/detail.vue')
  },
  {
    path: '/column/detail/:type/:id/:column_id',
    name: 'column',
    component: () => import('../pages/components/column-detail.vue')
  },
]

export {
  routers
}
