import { phpApi, pyApi } from '@/api/request'
import * as model from '../models'
import { getApiHost, getH5OssHost, ossHost } from '@/utils/utils'
import Axios, { AxiosPromise } from 'axios'
import envConfig from '@/config/env'
import store from '../../../store'

export function getShopId(pcHost: string): AxiosPromise<any> {
  return getOSSShopId().catch((e) => {
    // console.error('getOSSShopId fail: ', e)
    return getAPIShopId(pcHost)
  })
}

export function getAPIShopId(pcHost: string): AxiosPromise<any> {
  return Axios({
    baseURL: getApiHost(),
    method: 'GET',
    url: '/fairy/api/v1/shop/identifier/',
    headers: {
      // Origin: 'http://hfjnu123.pre.duanshu.com',
      // origin: 'http://1122334455.test.duanshu.com'
      origin: `https://${pcHost}`
    },
  })
}

export function getOSSShopId(host?: string): AxiosPromise<any> {
  return Axios({
    baseURL: ossHost,
    method: 'GET',
    url: `/shop/${getH5OssHost(host)}/config.json`,
    timeout: 3000
  })
}

// 店铺信息
// wiki：http://yapi.hogecloud.com/project/11/interface/api/332
export function getShopInfo({ params }: any): Promise<model.ShopInfo> {
  return phpApi({
    method: 'GET',
    url: `/h5/shop/info`,
    params
  })
}

// 模块开通信息
export function getModules() {
  return getOSSModules().catch(e => {
    console.error('getOSSModules fail: ', e)
    return getApiModules()
  })
}

export function getApiModules(): Promise<Array<model.Module>> {
  return pyApi({
    method: 'GET',
    url: `/fairy/api/v1/modules/`
  })
}

export function getOSSModules(): AxiosPromise<any> {
  return Axios({
    baseURL: ossHost,
    method: 'GET',
    url: `/shop/${getH5OssHost()}/modules.json`,
    timeout: 3000
  })
}

// 店铺装修：配置文件
export function getDecorateConfig({ params }: any) {
  return getOSSDecorateConfig().catch(e => {
    console.error('getOSSDecorateConfig fail: ', e)
    return getApiDecorateConfig(params)
  })
}

export function getApiDecorateConfig(params: object): Promise<model.DecorateConfig> {
  return pyApi({
    method: 'GET',
    url: `/fairy/api/v1/decorate/config/`,
    params
  })
}

export function getOSSDecorateConfig(): AxiosPromise<any> {
  return Axios({
    baseURL: ossHost,
    method: 'GET',
    url: `/shop/${getH5OssHost()}/decorate_config.json`,
    timeout: 3000
  })
}

export function getOSSCopyright(): AxiosPromise<any> {
  return Axios({
    baseURL: ossHost,
    method: 'GET',
    url: `/shop/${getH5OssHost()}/copyright.json`,
    timeout: 3000
  })
}

export function getDomain() {
  return phpApi({
    method: 'GET',
    url: `/fairy/api/v1/custom_domain/`,
  })
}
export function getCopyright() {
  return phpApi({
    method: 'GET',
    url: `/fairy/api/v1/pc_copyright/`,
  })
}
// 店铺装修：组件数据
// wiki: http://yapi.hogecloud.com/project/11/interface/api/266
export function getComponentList(param: object) {
  return phpApi({
    method: 'GET',
    url: `/h5/content/component/contents`,
    params: param
  })
}

// 指定查询组件内容
// wiki: http://yapi.hogecloud.com/project/11/interface/api/881
export function getComponentIdData(id: string, params: object): Promise<model.ApiList> {
  return phpApi({
    method: 'GET',
    url: `/h5/content/component/contents/${id}`,
    params
  })
}

// 私密登录 获取登录态
export function privateLogin(data: any) {
  return phpApi({
    method: 'POST',
    url: `/h5/client/check/login`,
    data
  })
}

// 用户信息
export function getUserInfo() {
  return phpApi({
    method: 'GET',
    url: `/h5/user/detail`
  })
}

// 按内容类型和关键词获取搜索结果列表
export function getSearchLists(type: string, params: object): Promise<model.ApiList> {
  return pyApi({
    method: 'GET',
    url: `/fairy/client/v1/product/search/${type}/`,
    params
  })
}

// 订阅列表
export function getSubscribeLists(params: object): Promise<model.ApiList> {
  return phpApi({
    method: 'GET',
    url: `/h5/content/user/column`,
    params
  })
}

// 免费订阅
export function orderFree(params: object): Promise<object> {
  return phpApi({
    method: 'GET',
    url: `/h5/content/common/subscribe`,
    params
  })
}

// 轮播图列表
export function getCarouselList(params: object): Promise<model.ApiList> {
  return pyApi({
    method: 'GET',
    url: `/fairy/api/v1/banner/`,
    params
  })
}

// 轮播图列表
export function getNavigationList(params: object): Promise<model.ApiList> {
  return pyApi({
    method: 'GET',
    url: `/fairy/api/v1/navigations/`,
    params
  })
}

// 顶部导航列表
export function getTopNavigationList(params: object): Promise<model.ApiList> {
  return pyApi({
    method: 'GET',
    url: `/fairy/api/v1/topnavigations/`,
    params
  })
}

// 小程序码
// wiki: http://yapi.hogecloud.com/project/11/interface/api/695
export function getMicroCode(params: object): Promise<object> {
  return phpApi({
    method: 'GET',
    url: `/h5/weapp/page/qrcode`,
    params
  })
}

// 微信服务情况
// wiki: http://yapi.hogecloud.com/project/11/interface/api/3232
export function wechatService(): Promise<object> {
  return phpApi({
    method: 'GET',
    url: `/fairy/api/v1/customer_services/`
  })
}

// PC网校详情
export function getPcSchoolDetail(params: object): Promise<object> {
  return pyApi({
    method: 'GET',
    url: `/fairy/api/v1/pc_school/config/`,
    params
  })
}

// 拼团活动详情
export function getPintuanDetail(id: string): Promise<model.PintuanDetail> {
  return pyApi({
    method: 'GET',
    url: `/fairy/api/client/v1/fightgroupactivity/${id}/`
  })
}
// 获取腾讯云cos上传凭证
// http://yapi.hogecloud.com/project/11/interface/api/3004
export function getUploadSign() {
  return pyApi({
    method: 'GET',
    url: `/h5/cos/upload/token`,
    params: {
      source: 'qcloud'
    }
  })
}

// 获取内容视频播放信息
// wiki: http://yapi.hogecloud.com/project/11/interface/api/5387
export function getPlayInfo(params: any) {
  let fileId = params.fileId
  let token = params.token
  return pyApi({
    method: 'GET',
    url: `/fairy/api/v1/videos/play_info/`,
    params: {
      file_id: fileId,
      token
    }
  })
}
