























import { Component, Vue, Prop } from 'vue-property-decorator'
import { Dialog } from 'element-ui'
import * as User from '@/utils/user'

  @Component({
    components: {
      [Dialog.name]: Dialog
    }
  })
export default class CommonLoginDialog extends Vue {
    visible: boolean = true

    show() {
      this.visible = true
    }

    toHome() {
      if (this.$route.name !== 'home') {
        this.$router.replace({ name: 'home' })
      }
      setTimeout(() => {
        this.$store.commit('SET_LOGIN_DIALOG', {
          visible: false
        })
        window.location.reload()
      }, 300)
    }
    toLogin() {
      let successUrl = window.location.href
      if (this.$route.name === 'error') {
        successUrl = window.location.origin
      }
      User.toLogin(successUrl)
    }
}
