/* eslint-disable */
export default {
  'home': {
    name: '发现',
    params: {},
    routeName: 'home'
  },
  // 'mine': {
  //   name: '我的',
  //   params: {},
  //   routeName: 'Mine'
  // },
  'content_list': {
    name: '内容列表',
    params: {
      content_type: '',
    },
    routeName: [
      'OfflineList',
      'contentList',
      'courseList'
    ],
    routeParams: {
      content_type(route: any) {
        let map: any = {
          'OfflineList': 'offlinecourse',
          'contentList': route.params.type,
          'courseList': 'course'
        }
        return map[route.name]
      }
    }
  },
  'content_detail': {
    name: '内容详情',
    params: {
      id: '',
      content_type: ''
    },
    routeName: [
      'OfflineDetail',
      'content',
      'course'
    ],
    routeParams: {
      id(route: any) {
        let map: any = {
          'OfflineDetail': 'id',
          'content': 'id',
          'course': 'id'
        }
        return route.params[map[route.name]]
      },
      content_type(route: any) {
        let map: any = {
          'OfflineDetail': 'offlinecourse',
          'course': 'course',
          'content': route.params.type
        }
        return map[route.name]
      }
    }
  },
  'order_list': {
    name: '订单列表',
    params: {
      status: '' // 订单状态
    },
    routeName: 'Purchase',
    routeParams: {
      status() {
        return ''
      }
    }
  },
  'order_detail': {
    name: '订单详情',
    params: {
      order_no: ''
    },
    routeName: ['NewPurchaseDetail'],
    routeParams: {
      orderNo: 'order_no'
    }
  }
}
