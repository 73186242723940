const routers = [
  {
    path: '/content/detail/:type/:id',
    name: 'content',
    component: () => import('../pages/content-detail.vue')
  },
  {
    path: '/content/list/:type',
    name: 'contentList',
    component: () => import('../pages/content-list.vue')
  }
]

export {
  routers
}
