// import { H5Host, getEnv } from '@/config/index'
import Store from '@/store'
import envConfig from '@/config/env'

interface StorageData {
  value: string|number
  timestamp?: number|null
}

interface ImgOptions {
  orient?: number
  width?: number|string
  height?: number|string
}

enum StorageMethod {
  localStorage = 'localStorage',
  sessionStorage = 'sessionStorage'
}

/*
 * 移动设备平台
 * */
export const mobileDevice = (ua?: any) => {
  let uaString = ua || navigator.userAgent
  let mbldevice = uaString.toLowerCase()
  if (/iphone|ipod|ipad/gi.test(mbldevice)) {
    return 'iOS'
  } else if (/android/gi.test(mbldevice)) {
    return 'Android'
  } else{
    return 'pc'
  }
}

export const isInServer = () => {
  const flag = typeof window === 'undefined'
  return flag
}

export const getShopId = () => {
  // const isServer = process && process.env && process.env.WEBPACK_TARGET === 'node'
  // const isServer = document === undefined
  // const isServer = true
  // console.log('process: ', process.env)
  // console.log('isServer: ', isServer)
  // if (isServer) {
  //   return Store.state.shopId
  // } else {
  //   return cookie.get('shopId')
  // }
  // console.log('utils Store ShopId: ', Store.state.shopId)
  return Store.state.shopId
}

export const getEnv = () => {
  if (envConfig && envConfig.env) {
    return envConfig.env
  }
  let host = Store.state.req.host
  if (!host) {
    const isServer = typeof document === 'undefined'
    if (!isServer) {
      host = window.location.host
    }
  }
  // console.log('getEnv Host: ', host)
  let env = 'release'
  if (/localhost|127.0.0.1|0.0.0.0/.test(host)) {
    env = 'test'
  }
  const domains = host.split('.')
  if (domains.length > 2) {
    const secondDomain = domains[1]
    if (/test/.test(secondDomain)) {
      env = 'test'
    }
    if (/pre/.test(secondDomain)) {
      env = 'pre'
    }
  }
  const firstDomain = domains[0]
  // console.log('getEnv firstDomain: ', firstDomain)
  if (/test-/.test(firstDomain)) {
    env = 'test'
  }
  if (/pre-/.test(firstDomain)) {
    env = 'pre'
  }
  // console.log('getEnv Env: ', env)
  return env
}

export const getApiHost = (from? : string) => {
  // console.log('===========> getApiHost: ', from)
  let host = ''
  const env = getEnv()
  switch (env) {
    case 'test':
      host = '//test-api.duanshu.com'
      break;
    case 'pre':
      host = '//pre-api.duanshu.com'
      break;
    case 'release':
      host = '//api.duanshu.com'
      break;
    default:
      host = '//api.duanshu.com'
      break;
  }
  if (isInServer()) {
    host = `http:${host}`
  }
  return host
}

export const ossHost = 'https://oss-web.duanshu.com'

export function getHost() {
  let host: string = ''
  if (envConfig && (envConfig as any).host) {
    host = (envConfig as any).host.replace(/http:\/\/|https:\/\//, '').replace('/#/', '')
  } else if (Store.state.req.host) {
    host = Store.state.req.host
  } else if (typeof window !== 'undefined') {
    host = window.location.host
  }
  return host
}

export function getH5OssHost(nHost?: string) {
  let host = nHost || getHost()
  return host.replace(/http:\/\/|https:\/\//, '')
    .replace('/#/', '')
    .replace('-pc', '')
    .replace('.pre', '')
    .replace(/\/$/, '')
    .replace(/\./g, '-')
}

export const getH5Host = () => {
  const shopId = getShopId()
  const env = getEnv()
  // h5_base_uri: "http://1122334455.test.duanshu.com"
  let shopHost = (Store.state.shopInfo as any).h5_base_uri
  let baseHost = ''
  let finialHost = ''
  switch (env) {
    case 'test':
      baseHost = '//test-h5.duanshu.com'
      break
    case 'pre':
      baseHost = '//pre-h5.duanshu.com'
      break
    case 'release':
      baseHost = '//h5.duanshu.com'
      break
    default:
      baseHost = '//h5.duanshu.com'
      break
  }
  if (shopHost) {
    finialHost = shopHost
  } else {
    finialHost = `https:${baseHost}/${shopId}`
  }
  return finialHost
  // return `${window.location.protocol}${host}/${shopId}`
  // return host
}

export const getMyHost = () => {
  const env = getEnv()
  let host = ''
  switch (env) {
    case 'test':
      host = '//test-my.duanshu.com'
      break
    case 'pre':
      host = '//pre-my.duanshu.com'
      break
    case 'release':
      host = '//my.duanshu.com'
      break
    default:
      host = '//my.duanshu.com'
      break
  }
  return `http:${host}`
}

export const utils = {
  createImgSrc: (data: any, options: ImgOptions = {}) => {
    if (utils.isBlank(data)) {
      return utils.getIconImgSrc()
    }
    let src: string = ''
    let op_str1: string = ''
    let op_str2: string = ''
    let op_str3: string = ''
    options.orient = 1

    if (options.orient) {
      op_str1 = '?imageMogr2/auto-orient'
    }
    if (options.width || options.height) {
      op_str2 = '/thumbnail/'
      if (options.width && options.height) {
        op_str2 += (options.width + 'x' + options.height)
      } else if (options.width) {
        op_str2 += (options.width + 'x')
      } else {
        op_str2 += ('x' + options.height)
      }
    } else {
      op_str2 = '/thumbnail/300x'
    }

    // 字符串链接
    if (utils.isString(data)) {
      src = data
      if (src.indexOf('aukey') !== -1) {
        return src
      }
      if (src.indexOf('.gif') !== -1) {
        return src
      }
      if (src.indexOf(op_str1) === -1) {
        src = [src, op_str1].join('')
      }
      if (src.indexOf('/thumbnail/') === -1) {
        src = [src, op_str2].join('')
      }
      if (src.indexOf('/cut/') !== -1) {
        let reg = /\/cut\/\d*x\d*x-\d*x\d*/
        if (reg.test(src)) {
          src = src.replace(/-/, '0')
        }
      }
      return src
    }

    if (data.query) {
      if (data.query.indexOf('imageMogr2/auto-orient') !== -1) {
        data.query = data.query.replace(/(.*?)imageMogr2\/auto-orient/g, function (string: any, key: any) {
          return ''
        })
      }
      if (data.query.indexOf('/cut/') !== -1) {
        let reg = /\/cut\/\d*x\d*x-\d*x\d*/
        if (reg.test(data.query)) {
          data.query = data.query.replace(/-/, '0')
        }
      }
      op_str3 = data.query.substring(0, 1) === '/' ? data.query : `/${data.query}`

      if (data.query.indexOf('auth_key') !== -1) {
        op_str1 = '?'
        op_str2 = ''
        op_str3 = data.query.substring(0, 1) === '/' || data.query.substring(0, 1) === '?' ? data.query.substring(1) : data.query
      }
    }

    if (data.file.indexOf('.gif') !== -1) {
      src = [data.host, data.file].join('')
    } else {
      src = [data.host, data.file, op_str1, op_str2, op_str3].join('')
    }

    src = src.replace(/^(http:\/\/|https:\/\/|\/\/)(.*?)/g, function (string, key) {
      return string.replace(string, 'https://')
    })
    return src
  },

  // 获取封面图：占位图
  getIconImgSrc: () => {
    return require('@/assets/common/default_img.png')
  },

  // 数字规则
  numberShowRules1(num: number | undefined) {
    if (!utils.isNumber(num) || !num) {
      return 0
    }
    if (num < 1000) {
      return num.toString()
    } else if (num < 10000) {
      return `${utils.retainDigits((num / 1000), 1)}k`
    } else if (num < 100000) {
      return `${utils.retainDigits((num / 10000), 1)}w`
    } else {
      return num.toString()
    }
  },

  // 保留多位数
  retainDigits: (value: string | number, digit: number = 2) => {
    return parseFloat(value.toString()).toFixed(digit)
  },

  // 判断是否json字符串
  isJsonStr: (str: any) => {
    if (typeof str === 'string') {
      try {
        JSON.parse(str)
        return true
      } catch (e) {
        return false
      }
    } else {
      return false
    }
  },

  // 判断是否为字符串
  isString: (obj: any) => { // 判断对象是否是字符串
    return Object.prototype.toString.call(obj) === '[object String]'
  },

  // 判断是否为字典
  isObject: (obj: any) => { // 判断对象是否是字典
    return Object.prototype.toString.call(obj) === '[object object]'
  },

  // 判断是否为数字
  isNumber: (value: any) => {
    // isNaN()函数 把空串 空格 以及NUll 按照0来处理 所以先去除，
    if((value === '' || value == null) && value != 0) {
      return false
    }
    if(!isNaN(value) && typeof value == 'number') {
      return true
    } else {
      return false
    } 
  },

  // 判断是否空
  isBlank: (str: any) => {
    if (Object.prototype.toString.call(str) === '[object Undefined]') {
      return true
    } else if (
      Object.prototype.toString.call(str) === '[object String]' ||
      Object.prototype.toString.call(str) === '[object Array]') {
      return !!(str.length === 0 || str === 'undefined')
    } else if (Object.prototype.toString.call(str) === '[object Object]') {
      return JSON.stringify(str) === '{}'
    } else if (Object.prototype.toString.call(str) === '[object Null]') {
      return true
    } else {
      return false
    }
  },

  // 判断是否为数字
  isNumberString: (val: any) => {
    val = Number(val)
    if (!isNaN(val)) {
      return true
    } else {
      return false
    }
  },

  getHttpsUrl: (url: any) => {
    if (url.length < 5) {
      return ''
    }
    if (url.indexOf('https') == 0) {
      return url
    }
    if (url.indexOf('https') != 0 && url.indexOf('http') == 0) {
      let sub_str = url.substr(4);
      let final_str = 'https' + sub_str;
      return final_str;
    } else {
      return ''
    }
  },

  operateTime: (time: number) => {
    return time < 10 ? '0' + time : '' + time
  },

  intervalTime(interval: number, format?: string) {
    format = format || 'min:sec'
    return format
      .replace('min', this.operateTime(Math.floor(interval / 60)))
      .replace('sec', this.operateTime(Math.floor(interval % 60)))
  },

  // 获取元单位价格
  getYuanPriceUnit: (val: any) => {
    if (utils.isNumber(val)) {
      val = utils.retainDigits(val / 100)
    } else if (utils.isString(val)) {
      val = utils.retainDigits(val)
    } else {
      val = null
    }
    return val
  }
}

export const storage = {
  method: 'localStorage',
  setStorage: (key: string, value: Object|string|number, time?: number, method?: string) => {
    if (isInServer()) {
      return
    }
    var data: StorageData, valid
    key = `${getShopId()}.${key || 'duanshu'}`
    method = method || storage.method
    data = {
      value: typeof value === 'object' ? JSON.stringify(value) : value
    }
    if (typeof time === 'number') {
      // 这里是一个坑 timestamp 是毫秒
      // time 单位为 秒
      if (time.toString().length === 10) {
        // 时间点
        data.timestamp = time * 1e3
      } else {
        // 时间间隔
        valid = time * 1e3
        data.timestamp = (new Date()).getTime() + valid
      }
    } else {
      data.timestamp = null
    }
    window[method].setItem(key, JSON.stringify(data))
  },
  getStorage: (key: string, method?: string) => {
    if (isInServer()) {
      return ''
    }
    var data, item, metaKey, metaData
    metaKey = `${getShopId()}.${key || 'duanshu'}`
    method = method || storage.method
    data = JSON.parse(window[method].getItem(metaKey))
    if (data && data.value) {
      if (data.timestamp) {
        if ((new Date()).getTime() < data.timestamp) {
          metaData = utils.isJsonStr(data.value) ? JSON.parse(data.value) : data.value
        } else {
          metaData = ''
          storage.removeStorage(key)
        }
      } else {
        metaData = utils.isJsonStr(data.value) ? JSON.parse(data.value) : data.value
      }
    } else {
      metaData = ''
    }
    return metaData
  },
  removeStorage: (key: string, method?: string) => {
    if (isInServer()) {
      return
    }
    key = `${getShopId()}.${key || 'duanshu'}`
    method = method || storage.method
    window[method].removeItem(key)
  },
  clearStorage: (method?: string) => {
    if (isInServer()) {
      return
    }
    method = method || storage.method
    window[method].clear()
  }
}

interface CookiesOptions {
  expires?: number | Date
  path?: string
  domain?: string
  secure?: string
}

export const cookie = {
  set: (name: string, value: string|null, options: CookiesOptions) => {
    if (isInServer()) {
      return
    }
    options = options || {};
    if (value === null) {
      value = ''
      options.expires = -1
    }
    var expires = ''
    if (options.expires && (typeof options.expires == 'number' || options.expires.toUTCString)) {
      var date;
      if (typeof options.expires == 'number') {
        date = new Date();
        date.setTime(date.getTime() + (options.expires * 24 * 60 * 60 * 1000));
      } else {
        date = options.expires;
      }
      expires = '; expires=' + date.toUTCString();
    }
    var path = options.path ? '; path=' + options.path : '';
    var domain = options.domain ? '; domain=' + options.domain : '';
    var secure = options.secure ? '; secure=' : '';
    document.cookie = [name, '=', encodeURIComponent(value), expires, path, domain, secure].join('');
  },
  get: (name: string) => {
    if (isInServer()) {
      return ''
    }
    var cookieValue = null
    if (document.cookie && document.cookie != '') {
      var cookies = document.cookie.split(';');
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].replace(/^\s+|\s+$/g, '');
        if (cookie.substring(0, name.length + 1) == (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }
}
//  (name, value, options) => {
//   if (typeof value != 'undefined') {
//     options = options || {};
//     if (value === null) {
//       value = '';
//       options.expires = -1;
//     }
//     var expires = '';
//     if (options.expires && (typeof options.expires == 'number' || options.expires.toUTCString)) {
//       var date;
//       if (typeof options.expires == 'number') {
//         date = new Date();
//         date.setTime(date.getTime() + (options.expires * 24 * 60 * 60 * 1000));
//       } else {
//         date = options.expires;
//       }
//       expires = '; expires=' + date.toUTCString();
//     }
//     var path = options.path ? '; path=' + options.path : '';
//     var domain = options.domain ? '; domain=' + options.domain : '';
//     var secure = options.secure ? '; secure' : '';
//     document.cookie = [name, '=', encodeURIComponent(value), expires, path, domain, secure].join('');
//   } else {
//     var cookieValue = null;
//     if (document.cookie && document.cookie != '') {
//       var cookies = document.cookie.split(';');
//       for (var i = 0; i < cookies.length; i++) {
//         var cookie = cookies[i].replace(/^\s+|\s+$/g, '');
//         if (cookie.substring(0, name.length + 1) == (name + '=')) {
//           cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
//           break;
//         }
//       }
//     }
//     return cookieValue;
//   }
// }

export const getH5Url = (route: object, type: string = 'vue') => {
  const shopId = getShopId()
  if (!shopId) {
    return ''
  }
  let host = getH5Host()
  let path = '#/system/redirect'
  let to = {
    type: type,
    routeData: route
  }
  let url = `${host}${path}?to=${encodeURIComponent(JSON.stringify(to))}`
  if(url.indexOf('passps') !== -1){
    // let urlArr = url.split('.')
    // urlArr[0] = urlArr[0].substring(0, urlArr[0].length - 3)
    // urlArr[1] = urlArr[1] + '.duanshu'
    // let urlArr2 = urlArr.slice(1, urlArr.length)
    // url = urlArr[0] + urlArr2.join('.')
    url = `https://m.passps.com${path}?to=${encodeURIComponent(JSON.stringify(to))}`
  }
  return url
}

export const accMul = (arg1: number, arg2: number) => {
  let m = 0
  let s1 = arg1.toString()
  let s2 = arg2.toString()
  try {
    m += s1.split('.')[1].length
  } catch (e) {
  }
  try {
    m += s2.split('.')[1].length
  } catch (e) {
  }
  return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m)
}

export const linkTo = ($router: any, link: any, userInfo: any, method: string = 'push') => {
  let flag = 1
  if (link.type === 'outLink') {
    let redirectUri = link.name
    let a = window.document.createElement('a')
    a.href = redirectUri
    if (userInfo && a.host.indexOf('xiuzan') !== -1) {
      let rekey = (link.name.indexOf('?') > -1) ? '&' : '?'
      redirectUri += `${rekey}userinfo=${userInfo}`
    }
    window.location.href = redirectUri
    flag = 0
  } else if (link.type === 'outlink') {
    window.location.href = link.id
    flag = 0
  } else if (link.type === 'course') {
    $router[method]({ name: 'course', params: { id: link.id } })
    flag = 0
  } else if (link.type === 'class') {
    $router[method]({ name: 'categoryContentList', params: { category_id: link.id } })
    flag = 0
  } else if (link.type === 'offlinecourse') {
    $router[method]({ name: 'OfflineDetail', params: { id: link.id } })
    flag = 0
  } else if (link.type !== 'none' && link.id) {
    $router[method]({ name: 'content', params: { id: link.id, type: link.type } })
    flag = 0
  }
  return flag
}
